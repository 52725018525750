import React from "react";
import "./Help.css"; // Import your CSS file

const Help = () => {
  return (
    <div className="help-container">
      <div className="help-section">
        <h2>Learn Sign</h2>
        {/* Lorem ipsum content for Learn Sign section */}
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. In a diam
          non quam vehicula elementum. Nulla facilisi. Aenean eu leo quam. Pellentesque
          habitant morbi tristique senectus et netus et malesuada fames ac turpis
          egestas. Mauris non quam eu libero viverra malesuada. Aenean euismod
          bibendum laoreet. Morbi leo risus, porta ac consectetur ac, vestibulum at
          eros. Pellentesque habitant morbi tristique senectus et netus et malesuada
          fames ac turpis egestas.
        </p>
      </div>
      <div className="help-section">
        <h2>Sign See</h2>
        <div className="sign-see-section">
          <h3>Processed Text Area</h3>
          <p>
            - A large, clearly defined area at the top left corner of the
            screen displays the translated text after sign language is
            recognized. Use a high-contrast background and large, easy-to-read
            font for accessibility.
          </p>
        </div>
        <div className="sign-see-section">
          <h3>Speech Recognition and Conversion Section</h3>
          <ul>
            <li>
              - Speech Recognition Toggle: A toggle switch with a microphone
              icon and clear labels ("On" / "Off") indicates speech recognition
              status. Use appropriate colors and consider adding an audio
              indicator for state changes.
            </li>
            <li>
              - Clear Button: A button to clear the current speech input,
              allowing a fresh start. Consider adding an icon and a clear label
              ("Clear").
            </li>
            <li>
              - Speech Input Field: A text field labeled "Speech input..."
              displays recognized speech. Use a clear border and high contrast
              between text and background.
            </li>
          </ul>
        </div>
        <div className="sign-see-section">
          <h3>Text to Sign Language Conversion</h3>
          <ul>
            <li>
              - Text Input Field: A designated area for users to type text
              manually, for conversion to sign language. Use a clear border
              and high contrast between text and background.
            </li>
            <li>
              - Conversion Button: A prominent "Convert to Sign Language" button
              prompts the system to process input and animate the avatar. Use a
              distinct color and clear label.
            </li>
          </ul>
        </div>
        <div className="sign-see-section">
          <h3>Avatar Display</h3>
          <ul>
            <li>
              - 3D Avatar: A central 3D avatar displays sign language
              animations. Use high-quality animation and consider options for
              users with visual impairments (e.g., text descriptions).
            </li>
            <li>
              - Avatar Selection: Users can select different avatars
              (character designs, sign language dialects). Use clear icons or
              labels.
            </li>
            <li>
              - Animation Controls: Sliders adjust animation speed and pause
              time between signs. Use clear labels and consider alternative
              input methods like buttons.
            </li>
          </ul>
        </div>

      </div>
    </div>
  );
};

export default Help;
