import React from "react";
import { Link } from "react-router-dom";

function Masthead() {
  return (
    <div className="container-fluid d-flex justify-content-center align-items-center home-gradient">
      <div
        className="row d-flex justify-content-center align-items-center"
        style={{ flexDirection: "column" }}
      >
        <div className="col-lg-7 text-white font-weight-bold display-1 text-center">
          Welcome to SignSee!
        </div>
        <div className="col-lg-4 divider my-4" />
        <div
          className="col-lg-7 container text-white-50 lead text-center"
          style={{ fontSize: "5rem !important" }}
        >
          The complete toolkit for Indian Sign Language. Explore our range of
          features which have been carefully designed keeping in mind the
          specific needs of people related to ISL.
        </div>
        <div className="d-flex justify-content-center mt-5">
          <Link to='/signSee/convert' className="nav-link">
          <button className="btn btn-info btn-lg me-3 px-3">Sign See</button></Link>
        <a href="https://learnsignbysignsee.ajnasnb.live/">  <button className="btn btn-info btn-lg px-3 me-3">Learn Signs</button></a>

        </div>
      </div>
    </div>
  );
}

export default Masthead;
